.referBtn {
    background-color: #552dba;
    width: "full";
    height: 50px;
    padding: 10px;
    color: white;
  }
  .referBtn:hover {
    background-color: #5b28da;
  }

  .search-input {
    margin-left: 0px;
    padding: 0px 12px 0px 40px;
    border: none;
    border-radius: 5px;
    width: 250px;
    height: 42px;
    margin-bottom: 10px;
    background-color: transparent !important;
    color: white !important;
  }
  
  .search-input::placeholder {
    color: #aaaaaa;
    opacity: 1;
  }
  
  .search-input:focus {
    border: none;
    outline: none;
    background-color: transparent !important;
    color: white !important;
  }
  
  .search-icon {
    color: rgb(170, 170, 170);
    position: absolute;
    left: 11px;
    top: 46%;
    transform: translateY(-50%);
    font-size: 22px;
  }
  
  .active-short{
    color: #346de9 !important;
  }


  .market-page {
  }
  
  .market-page .nav-tabs .nav-link.active {
    background-color: transparent !important;
    border-bottom: 3px solid #000 !important;
    color: #000;
    /* border-radius: 4px 4px 0 0; */
    border: none;
  }
  .is_dark .market-page .nav-tabs .nav-link.active {
    background-color: transparent !important;
    border-bottom: 3px solid #fff !important;
    color: #fff;
    /* border-radius: 4px 4px 0 0; */
    border: none;
  }
  
  .market-page .nav-tabs .nav-link {
    background: transparent !important;
    color: #999999;
    border-radius: 4px 4px 0 0;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* .market-page .nav-tabs {
    border-bottom: 1px solid gray !important;
  }
   */
  .market-page .nav-tabs .nav-link:hover {
    /* background-color: rgba(85, 45, 186, 0.8); */
    color: #000;
  }
  
  .is_dark .market-page .nav-tabs .nav-link:hover {
    /* background-color: rgba(85, 45, 186, 0.8); */
    color: #fff;
  }
  
  /* bottom nav */
  
  .refer-time.nav-tabs .nav-link.active {
    background-color: #f0f0f0 !important;
    border-bottom: none !important;
    color: #000;
    border-radius: 4px;
  }
  .is_dark .refer-time.nav-tabs .nav-link.active {
    background-color: #292929 !important;
    border-bottom: none !important;
    color: #fff;
    border-radius: 4px;
  }
  
  .refer-time.nav-tabs .nav-link {
    background: transparent;
    color: #000;
    border-radius: 4px 4px 0 0;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .is_dark .refer-time.nav-tabs .nav-link {
    background: transparent;
    color: #fff;
    border-radius: 4px 4px 0 0;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .refer-time.nav-tabs {
    border-bottom: none !important;
  }
  
  .refer-time.nav-tabs .nav-link:hover {
    color: #000;
  }
  
  .is_dark .refer-time.nav-tabs .nav-link:hover {
    color: #fff;
  }
  
  .heading-txt {
    position: relative;
    z-index: 1;
    margin-top: 86px;
    font-size: 48px;
    font-weight: 900;
    color: #000;
    line-height: 56px;
  }
  
  .heading-txt1 {
    position: relative;
    z-index: 1;
    margin-top: 21px;
    font-size: 24px;
    font-weight: 500;
    color: #000;
    line-height: 28px;
  }
  
  .refer-box {
    position: relative;
    z-index: 1;
    width: 487px;
    padding: 32px;
    background: #f5f5f5;
    border-radius: 12px;
    box-sizing: border-box;
    color: #000;
  }
  
  .refer-data {
    border: solid 1px #552dba;
    border-radius: 12px;
    padding: 50px 30px 50px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .refer-data-table {
    width: 100%;
    text-align: start; /* Center text in all cells */
    justify-content: space-between;
    background-color: transparent; /* White background for the table */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Subtle shadow for aesthetics */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Apply rounded corners smoothly */
  }
  
  .refer-data-table thead tr {
    background-color: transparent; /* Blue background for header */
    color: #ffffff80; /* White text for header */
    font-weight: bold;
  }
  
  .refer-data-table th {
    padding: 16px;
    color: #000;
  }
  .is_dark .refer-data-table th {
    color: #ffffff80;
  }
  .refer-data-table td {
    padding: 16px;
  }
  
  .refer-data-table tbody tr:nth-child(odd) {
    background-color: transparent; /* Light gray background for odd rows */
  }
  
  .refer-data-table tbody tr:nth-child(even) {
    background-color: transparent; /* White background for even rows */
  }
  
  .refer-data-table tbody tr:hover {
    background-color: transparent; /* Highlight row on hover */
  }
  
  .select_dropdown {
    width: 250px;
    border: 1px solid #1f1f1f;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
  }
  .is_dark .select_dropdown {
    width: 250px;
    border: 1px solid gray;
    padding: 10px;
    border-radius: 5px;
    background-color: #0e0e0e;
  }
  
  .select_dropdown:hover {
    border-color: #552dba;
  }
  
  .select_dropdown_content {
    width: 250px;
    border: 1px solid gray;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .is_dark .select_dropdown_content {
    width: 250px;
    border: 1px solid #1f1f1f;
    padding: 10px;
    border-radius: 5px;
    background-color: #0e0e0e;
  }
  
  .select_dropdown_content div:hover {
    background-color: #f5f5f5; /* Blue background on hover */
    border-radius: 4px;
  }
  .is_dark .select_dropdown_content div:hover {
    background-color: #2b2b2b; /* Blue background on hover */
    border-radius: 4px;
  }
  
  .box-content-referal {
    border: 1px solid #1f1f1f;
    border-radius: 15px;
    width: 500px;
    padding: 30px;
  }
  .is_dark .box-content-referal {
    border: 1px solid #552dba;
    border-radius: 15px;
    width: 500px;
    padding: 30px;
  }

  /* .market-nav{
    --bs-nav-tabs-border-color:green !important;
  } */
  .market-page .nav-tabs{
    border-bottom: none !important;
  }
  
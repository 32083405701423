.add_bank_account_page .form-control {
  border: 0.5px solid #ffffff7e;
  border-radius: 12px;
  min-height: 46px;
  padding-left: 20px;
  color: #fff !important;
}
.add_bank_account_page .form-control:focus{
  background-color: transparent;
  color: #fff;
}
.add_bank_account_page label {
  color: #fff;
  margin-bottom: 5px;
}
.add_bank_account_page .form-control:disabled{
  background-color: #4e5363d9;
}
.add_bank_account_page .common_add_bank_account_button {
  background-color: #ffb703;
  border-radius: 12px !important;
  color: #fff;
  width: 50%;
  font-weight: 600;
  height: 46px;
}
.add_bank_account_page input::-webkit-outer-spin-button,
.add_bank_account_page input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.add_bank_account_page input[type="number"] {
  -moz-appearance: textfield;
}

.p2pModal {
  border: 0.5px solid gray;
  border-radius: 12px;
}

.modal-content {
  color: #fff;
  /* border: solid 1px; */
  border-radius: 8px;
  padding: 20px;
  color: #000000;
}

.trading_page .add_post_tabs {
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 5px;
}

.trading_page .add_post_tabs .nav-item .nav-link {
  color: #fff;
}

.trading_page .add_post_tabs .buy_current_tab.active {
  background-color: #009D52;
}

.trading_page .add_post_tabs .sell_current_tab.active {
  background-color: #FF3A44;
}

.nav-tabs .nav-link {
  color: #fff;
  border: none;
  border-radius: 8px;
  background-color: #282828f7 !important;
}

.nav-tabs .nav-link.active {
  background-color: #ffb703 !important;
  color: #fff;
}

.form-control,
.form-select {
  background-color: #fff;
  color: #000;
  border: 1px solid #444;
}

/* .is_dark .form-control,
.form-select {
  background-color: #333;
  color: #fff;
  border: 1px solid #444;
} */

.modal-footer .btn {
  width: 150px;
  height: 50px;
}

.skiptranslate {
  .goog-te-gadget-simple {
    background: #fff;
    border: none;

    span span {
      margin-right: 7px;
    }
  }

  img {
    display: none;
  }
}


.is_dark .skiptranslate {
  .goog-te-gadget-simple {
    background: #1f1f1f;
  }
}

.bank-info {
  .bank-detail {
    font-size: 15px;
    margin-bottom: 5px;
  }

  div {
    line-height: 20px;
  }

  p {
    font-size: 13px;
    margin: 0px;
    padding: 0px;
  }

  .line-bnk {
    line-height: 20px;
  }
}

.notification-count {
  position: absolute;
  top: 3px;
  left: 37px;
  background: #eca901;
  padding: 0px 6px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
}

.shake {
  display: inline-block;
  animation: shake 1s ease-in-out infinite;
}

.notification-box {
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 13px 1px 15px 0px;
  border-bottom: 1px solid white;
  .message{
    h6{
      color: white;
      font-size: 14px;
    }
    p{
      font-size: 12px;
      margin: 0px;
      line-height: 18px;
    }
  }
}

.chat-box-heading {
  display: flex;
  gap: 20px;

  h4 {
    font-size: 14px;
    cursor: pointer;
    padding-bottom: 7px;
    margin-bottom: 11px;
  }

  .borderBot {
    border-bottom: 1px solid white;
  }
}

.loader-lottie {
  height: 300px;
}

.is_dark .form-control1 {
  background-color: transparent;
  border: none;
  background-clip: padding-box;
  color: #bbb;
  width: 100%;
  display: flex;
  padding: 4px;
  text-align: center;
}

.form-control1 {
  background: transparent;
  background-color: transparent;
  border: none;
  background-clip: padding-box;
  color: #bbb;
  padding: 4px;
}

.form-control1:focus {
  border: none;
  outline: none;
}

.textarea1 {
  background: transparent;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  color: white;
  padding: 4px;
}

.textarea2 {
  background: transparent;
  border-radius: 4px;
  color: white;
  width: 100px;
  height: 40px;
  padding: 4px;
}

.p2p-filter-border {
  border: 1px solid #474d57;
  padding: 5px 10px;
  border-radius: 8px;
}

.p2p-filter-border:hover {
  border: 1px solid #ffb703;
}

.p2pInputOuterDiv input {
  border: none;
  outline: none;
}

.form-control:focus {
  box-shadow: none !important;
}

.p2pInputOuterDiv input:focus,
.p2pInputOuterDiv input:focus-visible,
.p2pInputOuterDiv input:active {
  border: none;
  outline: none;
  box-shadow: none;
  /* Remove any shadow effects */
}

.selectMethod {
  word-wrap: normal;
  background: transparent;
  color: white;
  width: 100%;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.5rem;
  /* Optional padding for better visuals */
  cursor: pointer;
}

.selectMethod option {
  background-color: #212529;
  /* Dark background for dropdown */
  color: white;
  /* Text color */
  padding: 20px !important;
  border: none;
  /* No borders for options */
  cursor: pointer;
}

.back-borders {
  border-radius: 8px;
  border: 2px solid #ffb703;
  /* Blue border */
  box-shadow: 0 2px 6px #ffb703;
  /* Subtle blue backshadow */
}

.bank_transfer_form .form-control {
  background-color: transparent;
  border: 1px solid #fff;
  background-clip: padding-box;
  color: #bbb;
  padding: 10px;
}

.cstm_multi_select .react-select__control {
  padding: 10px;
  background: transparent;
}

.cstm_multi_select .react-select_menu {
  background-color: #000;
  color: #fff;
}

.ad_post_stepper_tabs.nav-tabs .nav-link.active {
  border: 1px solid;
}

.p2p_field_set fieldset {
  border: none;
}

.typeWriter {
  display: inline-flex;
}

.typeWriter span {
  word-break: break-all;
  height: 1.2em;
  width: 0%;
  overflow: hidden;
  animation: t 1s linear infinite alternate;
}

.typeWriter span:before {
  content: " ";
  display: inline-block;
}
.spinner-loader {
  animation: spinner-loader 2s linear infinite;
}

@keyframes spinner-loader {
  to {
    transform: rotate(360deg);
  }
}

@keyframes t {

  90%,
  100% {
    width: 100%;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(-15deg);
  }

  20% {
    transform: rotate(15deg);
  }

  30% {
    transform: rotate(-15deg);
  }

  40% {
    transform: rotate(15deg);
  }

  50% {
    transform: rotate(0deg);
  }
}


/* ======================= */

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 1px;
  /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #ffb703;
  /* Thumb color */
  border-radius: 4px;
  /* Rounded edges */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ffb703;
  /* Thumb hover effect */
}

::-webkit-scrollbar-track {
  background-color: lightgray;
  /* Track color */
  border-radius: 4px;
  /* Rounded edges */
}
.blog-align {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.blog-image {
  width: 800px;
  height: 500px;
}

.my_methods{
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;    
}       

.my_methods_btn{
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
}



@media (max-width:780px) {
    .my_methods{
        display: flex !important;
        flex-direction: column !important;
    }              
}
@media (max-width:500px) {
    .my_methods_btn{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .my_methods_btn button{
        margin: 0px 0px 2px 0px;
    }
}
.s-layout {
  margin-top: 90px;
}

.s-layout__content {
  flex: 1 1;
}

/* Sidebar */
.s-sidebar__trigger {
  z-index: 2;
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  height: 3.3em;
  background: #f7f7f7;
}

.s-sidebar__trigger > span {
  display: inline-block;
  margin: 0.9em 0 0 1.5em;
  color: #552dba;
}

.s-sidebar__nav {
  z-index: 2;
  /* font-family: "Open Sans", sans-serif; */
  position: fixed;
  top: 5rem;
  left: -20em;
  overflow: hidden;
  transition: all 0.3s ease-in;
  width: 20em;
  height: 100vh;
  font-weight: 500;
  /* border-right: 1px solid rgba(29, 29, 29, 0.08); */
  overflow-x: hidden;
  overflow-y: auto;
  padding: 40px 0px 0px;
  background: rgb(249, 249, 249);
  box-shadow: rgba(29, 29, 29, 0.08) -1px 0px 0px inset;

  min-height: calc(100vh - 150px);
}

.s-sidebar__nav:hover,
.s-sidebar__nav:focus,
.s-sidebar__trigger:focus + .s-sidebar__nav,
.s-sidebar__trigger:hover + .s-sidebar__nav {
  left: 0;
}

.s-sidebar__nav ul {
  position: absolute;
  top: 4rem;
  left: 0;
  margin: 0 0;
  padding: 0;
  width: 100%;
}

.s-sidebar__nav ul li {
  width: 100%;
  margin-top: 10px;
}

.s-sidebar__nav-link {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 4em;
}

.s-sidebar__nav-link span {
  position: absolute;
  top: 50%;
  left: 3em;
  transform: translateY(-50%);
  color: #000;
}

.s-sidebar__nav-link > i {
  position: absolute;
  top: 8px;
  left: 20px;
  display: inline-block;
  width: 2em;
  height: 2em;
  font-size: 1.3rem;
  color: rgba(140, 140, 140, 0.4);
}

.s-sidebar__nav-link > i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.s-sidebar__nav a {
  color: rgb(29, 29, 29);
  padding: 12px;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 8px;
  height: 48px;
  transition: all 0.3s ease 0s;
}

.s-sidebar__nav a svg {
  color: #b5b5b5;
}
.s-sidebar__nav ul li a:hover:not(.active) {
  background-color: #f2f2f3;
}
.s-sidebar__nav a.active {
  color: #000000 !important  ;
  border-right: 2px solid #ffb703;
  cursor: pointer;
  background: #f5f5f5;
}
.s-sidebar__nav a.active span,
.s-sidebar__nav a.active svg {
  color: #000000;
}

.marginTop {
  margin-top: 2rem;
}

/* custom table */

.order-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.order-table th {
  background-color: #131316; /* Dark background for header */
  color: white;
  font-weight: bold;
  padding: 12px;
  text-align: left;
}

.order-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.order-table tr:nth-child(odd) {
  background-color: #131313; /* Light shade for odd rows */
}

.order-table tr:nth-child(even) {
  background-color: #000000; /* Slightly darker shade for even rows */
}

.order-table tr:hover {
  background: #131316;
  color: white;
  transition: 0.3s ease-in-out;
}

/* Mobile First */
@media (min-width: 42em) {
  .s-layout__content {
    margin-left: 4em;
  }

  /* Sidebar */
  /* .s-sidebar__trigger {
    width: 4em;
  } */

  .s-sidebar__nav {
    width: 0em;
    left: 0;
  }

  .s-sidebar__nav:hover,
  .s-sidebar__nav:focus,
  .s-sidebar__trigger:hover + .s-sidebar__nav,
  .s-sidebar__trigger:focus + .s-sidebar__nav {
    width: 20em;
  }
}

@media (min-width: 68em) {
  .s-layout__content {
    margin-left: 20em;
  }

  /* Sidebar */
  .s-sidebar__trigger {
    display: none;
  }

  .s-sidebar__nav {
    width: 20em;
  }

  .s-sidebar__nav ul {
    top: 1.3em;
  }
}

@media (max-width: 480px) {
  .s-layout__content {
    margin-top: 55px !important;
  }
  .s-layout {
    display: grid !important;
    /* margin-top: 50px !important; */
  }
}

@keyframes refresh-spin {
  to {
    transform: rotate(360deg);
  }
}
.refresh-spin {
  animation: refresh-spin 0.75s linear infinite;
}

.refresh-spin-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.date_picker {
  height: 40px !important;
  cursor: pointer;
  background-color: #f5f5f5 !important;
}
.is_dark .date_picker {
  height: 40px !important;
  cursor: pointer;
  background-color: transparent !important;
  border: 1px solid #393c3e !important;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff !important;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
  background-color: #fff;
  color: #000 !important;
}

.daterangepicker .drp-calendar.left {
  background-color: #fff !important;
}
.daterangepicker .drp-calendar.right {
  background-color: #fff !important;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  color: #000 !important;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: transparent !important;
  border-color: transparent;
  color: #999 !important;
}

.daterangepicker .drp-buttons .btn {
  color: #000;
  border: 1px solid;
}

.daterangepicker td.in-range {
  background-color: #fff;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #f5f5f5 !important;
  border-color: transparent;
  color: inherit;
}

/* darkkkkk */

.is_dark .daterangepicker {
  height: 40px !important;
  cursor: pointer;
  background-color: #1f1f1f !important;
}

/* Calendar Background */
.is_dark .daterangepicker .calendar-table,
.is_dark .daterangepicker .drp-calendar.left,
.is_dark .daterangepicker .drp-calendar.right {
  background-color: #1f1f1f !important;
  border: 1px solid #3a3b3c; /* Subtle border */
  border-radius: 4px;
}

/* Header & Buttons */
.is_dark .daterangepicker.show-calendar .drp-buttons {
  display: block;
  background-color: #1f1f1f;
  color: #ffffff !important;
  border: 1px solid;
}

/* Calendar Text */
.is_dark .daterangepicker .calendar-table th,
.is_dark .daterangepicker .calendar-table td {
  color: #ffffff !important;
}

/* Disabled / Off Dates */
.is_dark .daterangepicker td.off,
.is_dark .daterangepicker td.off.in-range,
.is_dark .daterangepicker td.off.start-date,
.is_dark .daterangepicker td.off.end-date {
  background-color: transparent !important;
  border-color: transparent;
  color: #999 !important;
}

/* Selected Date Range */
.is_dark .daterangepicker td.in-range {
  background-color: #3a3b3c !important;
}

/* Buttons */
.is_dark .daterangepicker .drp-buttons .btn {
  color: white;
  border: 1px solid #ffffff;
  background-color: #3a3b3c;
}

.is_dark .daterangepicker .drp-buttons .btn:hover {
  background-color: #505152;
}

.camera-photo-view > .react-html5-camera-photo > video {
  width: 100%;
  height: auto;
}
/* .wrap-1 {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  height: 10vh !important;
  display: flex;
}
.sign {
  background-color: #fb4e4e;
  color: #fff !important;
} */

/* translate css */

.translate_Style .translate-container {
  display: flex;
  align-items: center;
  background: #f8f9fa;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  margin: 10px auto;
}

.translate-label {
  font-weight: bold;
  margin-right: 8px;
  color: #333;
}

#google_translate_element {
  display: inline-block;
}

.goog-te-gadget {
  font-size: 0px !important;
  font-family: "Arial", sans-serif !important;
  display: flex;

}

.goog-te-combo {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none;
}

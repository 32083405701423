.custom-stepper {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.step {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 40px;
}

.step::before {
  content: "";
  position: absolute;
  top: 0;
  left: 15px;
  width: 2px;
  height: 100%;
  background-color: #e0e0e0;
}

.step.active::before {
  background-color: #6200ea;
}

.step-header {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.step-number {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e0e0e0;
  border-radius: 50%;
  font-weight: bold;
  background-color: #fff;
  color: #6200ea;
}

.step.active .step-number {
  border-color: #6200ea;
  background-color: #6200ea;
  color: #fff;
}

.step-label {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

/* .step-content {
  margin-top: 10px;
  padding-left: 10px;
  border-left: 2px solid #6200ea;
} */

.step-content p {
  margin: 0 0 10px;
}

.step-actions {
  display: flex;
  gap: 10px;
}

.step-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.step-button.back {
  background-color: #e0e0e0;
  color: #333;
}

.step-button.back:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.step-button.next {
  background-color: #6200ea;
  color: #fff;
}

.step-button.next:hover {
  background-color: #3700b3;
}

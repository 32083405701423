.add_post_stepper_page .add_post_tabs{
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 5px;
}
.add_post_stepper_page .add_post_tabs .nav-item .nav-link{
    color: #fff;
}
.add_post_stepper_page .add_post_tabs .buy_current_tab.active{
    background-color: #009D52;
}
.add_post_stepper_page .add_post_tabs .sell_current_tab.active{
    background-color: #FF3A44;
}
.add_post_stepper_page .add_post_stepper_steps span{
    color: #fff;
}
.add_post_stepper_page .form-control{
    border: 0.5px solid #ffffff7e ;
    border-radius: 4px;
    min-height: 46px;
    padding-left: 20px;
    color: #fff !important;
}
.add_post_stepper_page .input-group-text{
    color: #fff;
    padding-right: 20px;
    padding-left: 20px;
}
.add_post_stepper_page label{
    color: #fff;
    margin-bottom: 5px;
}

.add_post_stepper_page .input-group{
    border: 0.5px solid #ffffff7e;
    border-radius: 4px;
    overflow: hidden;
}
.add_post_stepper_page .input-group .form-control{
    border-right: none;
     border-left: none;
}
.add_post_stepper_page .input-group .input-group-text{
    border-left: none !important;
    border-right: none !important;
}

.add_post_stepper_page .form-control:focus{
    background-color: transparent;
    color: #fff;
}
 .common_stepper_button{
    background-color: #ffb703;
    border-radius: 12px !important;
    color: #fff;
    width: 50%;
    font-weight: 600;
    height: 46px;
}
.common_stepper_button:hover{
    background: transparent;
    border: 1px solid #ffb703;
    transform: scale(1);
    box-shadow: 0px 1px 10px #ffb703;
}
.common_add_post_stepper_disabled_button{
    border: none;
}
.add_post_stepper_page input::-webkit-outer-spin-button,
.add_post_stepper_page input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.add_post_stepper_page input[type="number"] {
    -moz-appearance: textfield;
}
.pay_trans_modal .dragdrop-container {
    border: 2px dashed #ecececb8;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 170px;
    justify-content: center;
    border-radius: 3px;
    overflow: hidden;
    }
    .pay_trans_modal .dragdrop-container img{
        visibility: visible;
    }
.pay_trans_modal .commonImgs {
    position: relative;
    display: inline-block;
  }

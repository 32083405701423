/* Button Styles */
/* Button Styles */
.P2pbutton {
  background-color: transparent;
  color: white;
  font-size: 16px;
  /* Readable font size */
  font-weight: 500;
  /* Slightly bold for better emphasis */
  padding: 4px 16px;
  /* Balanced padding */
  cursor: pointer;
  /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease, color 0.3s ease;
  /* Smooth transitions */
  width: 120px;
  /* Slightly larger width for better interaction */
  height: 45px;
  text-align: center;
  /* Center text inside the button */
}

.p2p-styles {

}

.preview-box {
  position: absolute;
  bottom: 55px;
  left: 14px;
}

.preview-box .cross-img {
  color: white;
  position: absolute;
  z-index: 111;
  top: -29px;
  left: 3px;
  background: black;
  font-size: 22px;
  border-radius: 50%;
  cursor: pointer;
}

.show-chat-img {
  height: 80px;
  width: 80px;
  border-radius: 10px;
  display: inline-block;
  background: #eeeeee;
  top: 0px;
  position: relative;
  margin-top: 5px;
}

.preview-img {
  margin: 0px 0px 5px;
  height: 80px;
  width: 80px;
  border-radius: 10px;
  display: inline-block;
  background: #eeeeee;
  top: -26px;
  position: relative;
}

.file-upload {
  position: relative;

  input {
    width: 31px;
    position: absolute;
    top: -3px;
    visibility: hidden;
    cursor: pointer;
  }
}

.send-msg-icon {
  color: #383838 !important;
  position: absolute;
  right: 35px;
  font-size: 25px;
  cursor: pointer;
}

.attach-icon {
  font-size: 29px;
  color: white;
  cursor: pointer;
}

.trading-filter {
  .btn {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

.first-char {
  background: white;
  color: black;
  border-radius: 50%;
  padding: 6px 11px;
}

.P2pbutton2 {
  color: white;
  font-size: 16px;
  /* Readable font size */
  font-weight: 500;
  /* Slightly bold for better emphasis */
  padding: 4px 16px;
  /* Balanced padding */
  cursor: pointer;
  /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease, color 0.3s ease;
  /* Smooth transitions */
  width: 120px;
  /* Slightly larger width for better interaction */
  height: 45px;
  text-align: center;
  /* Center text inside the button */
  background-color: #ffb703;
}

.P2pbutton:hover {
  background: #ffb703;
  color: white;
  /* Text color contrasts background */
  transform: scale(1.05);
  /* Slight zoom-in effect */
  border-radius: 8px;
  /* Smooth rounded corners */
}

/* Container Styles */
.ButtonBox {
  overflow: hidden;
  padding: 0px;
  display: flex;
  /* Flexbox for alignment */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  height: 40px;
  /* Increased height for better visual proportion */
  width: 240px;
  /* Increased width for balance with the button */
  background-color: transparent;
  /* Subtle gray background for better contrast */
  border: 1px solid #dcdcdc;
  /* Light gray border for a subtle outline */
  border-radius: 10px;
  /* Smooth rounded corners */
  transition: box-shadow 0.3s ease;
  gap: 2px;
}

.pay_meth_dropdown .dropdown-menu {
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
}

.pay_meth_dropdown .dropdown-menu .dropdown-item a {
  color: #fff;
}

.pay_meth_dropdown .dropdown-menu .dropdown-item:hover a {
  color: #000;
}

.pay_meth_dropdown .dropdown-menu .dropdown-item:not(:last-child) {
  border-bottom: 1px solid #ffffff78;
}

.pay_meth_listing_box {
  border: 0.5px solid #fff;
  border-radius: 8px;
  overflow: hidden;
}

.pay_meth_listing_box .box-p {
  font-size: 12px;
  margin-bottom: 2px;
}

.p2p_buy_sell td {
  padding: 20px 10px;
}

.login_btn:disabled {
  opacity: 0.7;
}

.p2p_buy_sell tr th {
  font-weight: 400;
  font-size: 12px;
}

.p2p_user_img {
  height: 35px;
  width: 35px;
  border: 1px solid #bbb5b5;
  color: #ffffff;
  border-radius: 12px;
}

.hover-bg-gray:hover {
  background: gray !important;
  color: black !important;
}

.name_p2p_list {
  border-radius: 8px;
  background: #fff;
  color: #000;
  font-size: 12px;
  padding: 2px;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.bounce_1 {
  animation: bounce 1.5s infinite;
}

.role-btn {
  cursor: pointer;
}

.jodit-workplace {
  height: 350px !important;
}

/* For Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Button */
.theme-button {
  min-width: 120px;

  position: relative;
  cursor: pointer;

  padding: 12px 17px;
  border: 0;
  border-radius: 7px;

  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  background: radial-gradient(ellipse at bottom,
      rgba(71, 81, 92, 1) 0%,
      rgba(11, 21, 30, 1) 45%);

  color: rgb(255, 255, 255, 0.66);

  transition: all 1s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.gray-borders {
  border: 0.5px solid #959595 !important;
  box-shadow: none !important;
}

.button-green-an:disabled {
  /* background-color: #a5d6a7;
  color: #ffffff80; 
  cursor: not-allowed; */
  opacity: 0.6;
}

button:disabled:hover {
  cursor: not-allowed;
}

.button-green-an {
  padding: 4px 20px;
  border: 2px solid #74fbba;
  background-color: #009d52;
  color: #ffffff !important;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.4s ease;
  outline: none;
  position: relative;
  overflow: hidden;
}

.button-green-an::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle,
      rgba(255, 255, 255, 0.25) 0%,
      #009d52 70%);
  transform: scale(0);
  transition: transform 0.5s ease;
}

.button-green-an:hover::after {
  transform: scale(4);
}

.button-green-an:hover {
  border-color: #74fbba;
  background: #009d52;
}

.button-red-an:disabled {
  opacity: 0.6;
}

.button-red-an:disabled:hover {
  cursor: not-allowed;
}

.button-red-an {
  padding: 4px 20px;
  border: 2px solid #fb7f74;
  background-color: #9d0000;
  color: #ffffff !important;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.4s ease;
  outline: none;
  position: relative;
  overflow: hidden;
}

.button-red-an::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle,
      rgba(255, 255, 255, 0.25) 0%,
      #9d0000 70%);
  transform: scale(0);
  transition: transform 0.5s ease;
}

.button-red-an:hover::after {
  transform: scale(4);
}

.button-red-an:hover {
  border-color: #fb7f74;
  background: #9d0000;
}

/* Bounce keyframes */
@keyframes bounce {

  0%,
  100% {
    transform: translateY(5);
  }

  50% {
    transform: translateY(-5px);
  }
}

/* Define the rotation animation */
@keyframes loading-rotater {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Apply the animation to the spinner-border class */
.loading-rotate {
  animation: loading-rotater 1s linear infinite;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.manu_header .dropdown-toggle img {
}
.manu_header .dropdown-toggle:after {
  display: none;
}
.navbar-toggler-icon {
  width: unset !important;
  height: unset !important;
  background-image: none !important;
}

ul.custom_dropdown_menu li a {
  padding: 5px 10px !important;
  margin: 0 !important;
  line-height: 20px;
}
ul.custom_dropdown_menu {
  gap: 0px;
  align-items: center;
}

.dropdown-toggle:after {
  display: inline-block;
  font-family: "Font Awesome 6 Free" !important;
  font-size: 65%;
  font-weight: 900;
  content: "\f107";
  /* margin-left: 5.5rem; */
  transition: 0.3s ease;
  border: none;
  /* margin-top: 3px; */
  position: relative;
  top: 3px;
  color: #000000;
}
.border_btn {
  border: 1px solid gray;
  border-radius: 5px;
  transition: all 0.6s ease-in-out;
}
.border_btn:hover {
  background: transparent;
  border: 1px solid  var(--theme-primary-color);
  box-shadow: 0px 4px 15px  var(--theme-primary-color); /* Smooth glowing effect */
  transform: scale(1); /* Slightly enlarges the button */
}
.exchange-header .nav-item .nav-link {
  display: block;
  font-size: 15px;
  padding: 5px 10px;
  margin: 0 10px;
  color: #666;
  font-weight: 500;
}
.home_nav .navbar-brand img {
  padding-right: 20px;
}

.home_nav {
  color: #1f1f1f;
  padding: 10px !important;
  background-color: #ffffff;
  position: fixed;
  backdrop-filter: blur(10px);
  /* border-bottom: 0.5px solid #808080a8; */
  /* -webkit-backdrop-filter: blur(10px);  */
  /* border-bottom: 1px solid #f2f2f2; */
}

.navbar .offcanvas-body {
  align-items: center;
}

@media (min-width: 480px) {
  .home_nav .navbar-brand img {
    padding-right: 20px;
  }

  /* .dropdown-toggle::after {
    border-top: 0.3em solid #c1bcbc !important;
    top: 3px;
    position: relative;
  } */
}

.home_nav .navbar-nav .nav-item .nav-link {
  display: block;
  font-size: 14px;
  padding: 10px 10px;
  margin: 0 5px;
  color: #000000;
}

.header-nav-item {
  position: relative;
}

/* .header-nav-item:hover .mega-mag.mega-half {
  width: 250px;
}
.header-nav-item:hover .mega-mag {
  opacity: 1;
  position: absolute;
  height: 400px;
  width: 800px;
  padding: 0;
  left: 0px;
  right: 0;
  top: 40px;
  
} */
/* .mega-mag {
  opacity: 0;
  height: 0;
  top: 50px;
  width: 0;
  background-color: red;
 
} */
/* .menu-minheight-left {
  height: 360px;
 
  overflow-y: auto;
  overflow-x: hidden;
} */

.menu-minheight-right {
  height: 331px;
  /* min-height: 300px; */
  overflow-y: auto;
  overflow-x: hidden;
}
.menu-minheight-right-mini {
  height: 280px;
  /* min-height: 300px; */
  overflow-y: auto;
  overflow-x: hidden;
}

@media all and (max-width: 992px) {
  .dropdown-toggle:after {
    display: block;
    font-family: "Font Awesome 6 Free" !important;
    font-size: 87%;
    font-weight: 900;
    content: "\f107";
    transition: 0.3s ease;
    border: none;
    position: absolute;
    right: 21px;
    top: 15px;
    color: #b5b5b5;
  }

  ul.dropdown-menu.dropdown_body_custom {
    box-shadow: none !important;
    padding-top: 0 !important;
  }

  .dropdown-body-item-subtitle {
    display: none !important;
  }
  .dropdown-body-item-icon-img {
    width: 21px !important;
    height: 21px !important;
  }

  .dropdown-body-item-icon {
    width: 34px !important;
  }
  .dropdown-body-item-content {
    padding-top: 4px !important;
  }

  .right_part {
    display: none;
  }
}

@media all and (min-width: 992px) {
  /* .navbar .nav-item .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    margin-top: 0;    
  } */
  /* .navbar .dropdown-menu.fade-down {
    top: 80%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
  }
  .navbar .dropdown-menu.fade-up {
    top: 180%;
  } */
  /* .navbar .nav-item:hover .dropdown-menu {   
    opacity: 1;
    visibility: visible;
    top: 100%;
  } */

  .mega-menu-pills.nav-pills .nav-link.active,
  .mega-menu-pills.nav-pills .show > .nav-link {
    border-bottom: none !important;
    background-color: #f9f9f9 !important;
    color: #222;
    padding: 1rem;
    margin-bottom: 5px;
    border-radius: 1rem;
  }

  .having_submenu {
    position: relative;
  }
  /* .having_submenu .dropdown-body-item i {
    display: none;
  }
  .having_submenu .dropdown-body-item:hover i {
    display: block;
   
  } */

  a.nav-link.dropdown-body-item.having_submenu {
    padding: 0 !important;
  }
  .mega-menu-pills.nav-pills .nav-link:hover.having_submenu:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    position: absolute;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid #666;
    right: 23px;
    top: 29px;
  }
}

/* @media all and (min-width: 992px) {
  .navbar-nav li:hover > ul.dropdown-menu {
    display:block;
  }
  .dropdown-submenu {
    position: relative;
  }
  .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    
  }

 
  .dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg);
  }
 
} */

.mega-dropdown-menu {
  padding: 0 !important;
  width: 400px;
}

.mega-dropdown-menu-more {
  width: 700px;
}
.right_part {
  background: #f6f6f69e;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  padding: 1rem;
}

.r_lg {
  border-top-right-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
}

ul.dropdown-menu.dropdown_body_custom.dropdown-menu-end[data-bs-popper] {
  right: -75px !important;
  left: auto;
}

ul.dropdown-menu.dropdown_body_custom {
  padding-top: 1rem;
  border: 0 !important;
  background: #fff !important;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 20px #d8d6d6b5;
  top: 56px;
}

ul.dropdown-menu.dropdown_body_custom .dropdown-item:hover,
ul.dropdown-menu.dropdown_body_custom .dropdown-item:focus {
  background-color: #fbfbfb;
}

/* ul.dropdown-menu.dropdown_body_custom li:first-child .dropdown-item {
  border-top-left-radius: 2.1rem;
  border-top-right-radius: 2.1rem;
} */

/* ul.dropdown-menu.dropdown_body_custom li:last-child .dropdown-item {
  border-bottom-left-radius: 2.1rem;
  border-bottom-right-radius: 2.1rem;
} */
ul.dropdown-menu.dropdown_body_custom .dropdown-body-item svg {
  color: #c4c2c3;
}

.menu_jambotron {
  padding: 0.8rem;
  border-radius: 1rem;
  border: 1px solid #eee;
  color: #333;
}
.menu_jambotron:hover {
  background-color: #fbfbfb;
}
.menu_jambotron:hover a,
.menu_jambotron a {
  color: #333;
}

ul.dropdown-menu.dropdown_body_custom .dropdown-divider {
  border-top: 1px solid rgb(199 199 199 / 18%) !important;
}

ul.dropdown-menu.dropdown_body_custom .dropdown-body-item {
  /* background-color: #f9f9f9; */
  padding: 0.5rem 1rem !important;
  margin-bottom: 5px !important ;
  /* border-radius: 1rem !important; */
}

ul.dropdown-menu.dropdown_body_custom .border-top,
ul.dropdown-menu.dropdown_body_custom .border-bottom {
  border-color: #dee2e66e !important;
}

/* .dropdown_body_custom .dropdown-body-item:hover {
  background-color: #f9f9f9 !important;
  color: #222;
  
} */

/* .dropdown_body_custom .dropdown-item:hover {
  background-color: #f9f9f9 !important;
  color: #222;
} */

.dropdown-body-item-icon {
  width: 45px;
  padding-left: 10px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.dropdown-body-item-title {
  font-size: 14px;
  line-height: 16px;
  color: #222;
  margin-bottom: 4px;
}

.dropdown-body-item-subtitle {
  font-size: 12px;
  line-height: 14px;
  color: #1c1c1e;
  opacity: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.dropdown-body-item-icon-img {
  width: 30px;
  height: 30px;
}

/* .dropdown-body-item-content {
  padding: 3rem ;
} */

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid #ffb703;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

#user_link .dropdown-toggle::after {
  display: none;
}

/* .dropdown_right {
  right: -100%;
  width: 320px;
} */

.w320px {
  min-width: none;
  width: 280px;
}

.no_border_table thead tr {
  border-color: transparent;
  font-size: 13px;
}

.no_border_table tbody tr {
  /* height: px; */
  border-color: transparent !important;
  font-size: 13px;
  vertical-align: middle;
}
.no_border_table thead tr th {
  color: #546d92;
  font-weight: normal;
  /* font-size: 12px; */
}

.no_border_table tr:hover {
  background: rgb(189 188 188 / 8%) !important;
}

/* 04-02-2025 */
.Header_Section .navbar {
}

@media screen and (max-width: 480px) {
  .home_nav {
    padding: 0rem 0rem !important;
    background: black !important;
  }

  /* .dropdown-toggle:after {
    display: block;
    font-family: "Font Awesome 6 Free"!important;
    font-size: 1rem;
    font-weight: 900;
    content: "\f107";
    margin-left: 0.5rem;
    transition: .3s ease;
    border: none;
    margin-top: 3px;
    left: 95%;
    position: relative;
} */
}

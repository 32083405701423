/* Enhanced Shimmer Effect */
.shimmer {
  position: relative;
  background: #e2dddd;
  background-image: linear-gradient(
    to right,
    #e0e0e0 0%,
    #f0f0f0 10%,
    #ffffff 20%,
    #f0f0f0 30%,
    #e0e0e0 50%,
    #e0e0e0 100%
  );
  background-repeat: no-repeat;
  background-size: 1000px 200px;
  animation: shimmer 1.2s infinite linear;
}

.is_dark .shimmer {
  position: relative;
  background: #3a3a3a;
  background-image: linear-gradient(
    to right,
    #2c2c2c 0%,
    #555555 10%,
    #888888 20%,
    #555555 30%,
    #2c2c2c 50%,
    #2c2c2c 100%
  );
  background-repeat: no-repeat;
  background-size: 1000px 200px;
  animation: shimmer 1.2s infinite linear;
}

/* Shimmer Animation */
@keyframes shimmer {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}

/* Container Styling */
#container {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Square Box */
#square {
  width: 135px;
  height: 135px;
}

/* Content Section */
#content {
  flex: 1;
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

/* Title Placeholder */
#content-title {
  width: 100%;
  height: 30px;
  margin-bottom: 1rem;
}

/* Description Placeholder */
#content-desc {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}

/* Lines */
.line {
  width: 100%;
  height: 10px;
}

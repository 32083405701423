.notification__body {
  padding-right: 30px;
}
.notification__close {
  position: absolute;
  top: 22px;
  right: 15px;
  /* display: none; */
}

.notification--type-success {
    border-color: #2FCF9C;
    color: #e9e9e9;
    z-index: 10000 !important;
    position: fixed  !important;
    background-color: rgba(32, 19, 1, 0.541);
    text-align: center;
    padding-top: 260px;
    padding-bottom: 200px;
    padding-left: 20px;
    font-size: 60px;
    /* top: 15vh; */
    width: 100%;
    height: 100%;
}

.notification--type-error {
  border-color: #D74141;
  color: #D74141;
}

.notification--type-notify {
  border-color: #085A7F;
  color: #085A7F;
}